<template>
  <div>

    <loading :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage">
    </loading>

    <div class="row justify-content-md-center">
      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <form id="processes-list-filter" ref='processesListFilter' v-on:submit.prevent="applyFilter()">
              <h5 class="mb-0">Filtrar por:</h5>

              <!-- Criado por -->
              <div class="row">
                <div>
                  <multiple-select labelField="nome" :options="userList" :id-field="'id'"
                    :placeholder="this.$i18n.t('form.task.created_by')" :fetchOptions="userFetchOptions"
                    :value="filters.filUsers" />
                </div>
              </div>

              <!-- Data -->
              <h6 class="ml-3 mb-2 mt-4">Data de Processamento</h6>
              <div class="row">
                <div class="py-1 col-md-6">
                  <div class="md-form my-0">
                    <div class="input-group-icon input-group-icon-right mb-0">
                      <div class="md-form my-0">
                      <span class="input-icon input-icon-right">
                        <i class="ti-calendar"></i>
                      </span>
                        <input data-index="2" :title="this.$i18n.t('form.booking-control.etd')"
                               id="etd_begin_date" ref="etd_begin_date" readonly
                               class="form-date md-form-control" type="text"
                               :value="$util.formatDate(filters.filEtdInicio)">
                        <label :class="{'active': filters.filEtdInicio}">{{ $t('filter.Start') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="py-1 col-md-6">
                  <div class="md-form my-0">
                    <div class="input-group-icon input-group-icon-right mb-4">
                      <div class="md-form mb-4 mt-0">
                        <span class="input-icon input-icon-right">
                          <i class="ti-calendar"></i>
                        </span>
                        <input data-index="2" :title="this.$i18n.t('form.booking-control.etd')"
                               id="etd_end_date" ref="etd_end_date" readonly class="form-date md-form-control"
                               type="text" :value="$util.formatDate(filters.filEtdFim, undefined, null)">
                        <label :class="{'active': filters.filEtdFim}">{{ $t('filter.End') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}
                      </button>
                    </div>
                    <div class="col-6">
                      <button type="button" v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">{{
                        $t('filter.reset') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <div class="col-12 col-sm-12">
        <div class="row">
          <div class="col-12 px-0">
            <div class="card">
              <div class="card-body">
                <!--  Actions -->
                <div class="flexbox px-0 py-3 mb-3">
                  <div class="d-flex align-items-center">
                    <h5 class="box-title mb-3 mt-1">Histórico de processamento de EDIs financeiros</h5>
                  </div>
                  <div class="btn-group" role="group" aria-label="false">
                    <router-link :to="{name: 'EdiDownload'}" class="btn btn-sm btn-outline-light pull-right">
                      Novo
                    </router-link>
                  </div>
                </div>
                <!--  End Actions -->
                <!-- Table -->
                <div class="table-responsive px-0">
                  <vuetable ref="vuetable"
                    :noDataTemplate="this.$i18n.t('noting to result')"
                    :api-url="endpoint"
                    :fields="vTableFields"
                    :sort-order="sortOrder"
                    :css="css.table"
                    pagination-path="pagination"
                    :per-page="20"
                    :multi-sort="multiSort"
                    :append-params="filter"
                    multi-sort-key="shift"
                    :http-options="httpOptions"
                    track-by="id_process"
                    @vuetable:pagination-data="onPaginationData"
                    @vuetable:loading="onLoading"
                    @vuetable:loaded="onLoaded"
                    @vuetable:cell-clicked="onRowClicked">
                    <template slot="actions" slot-scope="props">
                      <div class="custom-actions">
                        <!-- <router-link :to="{ path: `/processdetail?${props.rowData.id_process}`}"> -->
                          <i class="text-primary  rc-Ativo-210 font-20" @click="onActionsClicked(props)"></i>
                        <!-- </router-link> -->
                      </div>
                    </template>
                  </vuetable>

                </div>
                <vuetable-pagination ref="pagination"
                  :css="css.pagination" @vuetable-pagination:change-page="onChangePage"
                ></vuetable-pagination>
                <!-- END Table -->
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row">
          <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onShow">
            Console
          </button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable, { VuetablePagination } from 'vuetable-2'
import FilterSideBar from '@/components/FilterSideBar.vue'
import MultipleSelect from '@/components/Forms/MultipleSelect.vue'

// import dayjs from 'dayjs'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

import EmployeeService from '@/services/EmployeeService'

export default {
  name: 'BookingList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.booking') + ' - %s'
    }
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      selectedProcess: null,
      processDetail: false,
      endpoint: process.env.VUE_APP_APIENDPOINT + 'v3/edi',
      multiSort: true,
      statusOptions: [
        'Processando',
        'Erro',
        'Gerado'
      ],
      employeeList: [],
      userList: [],
      customerList: [],
      activeFilterList: {
        begin_date: null,
        end_date: null
      },
      filter: {},
      fieldsTable: [
        {
          name: 'data',
          title: 'Data do Processamento',
          sortField: 'data',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(this.$util.formatDate(value))
        },
        {
          name: 'arquivo',
          title: 'Arquivo',
          sortField: 'arquivo',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'responsavel',
          title: 'Responsável',
          sortField: 'responsavel',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'situacao',
          title: 'Situação',
          sortField: 'situacao',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.formatStatus(value)
        // },
        // {
        //   name: 'actions',
        //   title: 'Ações',
        //   width: '150px',
        //   dataClass: 'text-center',
        //   titleClass: 'text-center'
        }
      ],
      sortOrder: [
        { field: 'data', direction: 'desc' }
      ],
      httpOptions: {
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      css: {
        table: {
          tableClass: 'table table-hover align-middle table-clickable',
          rowCss: 'teste',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      }
    }
  },
  components: {
    FilterSideBar,
    Vuetable,
    VuetablePagination,
    MultipleSelect,
    Loading
  },
  // Apply filters to local filter
  beforeMount () {
    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    // this.$store.dispatch('setShowSearch', true)

    this.$jQuery('#etd_begin_date, #etd_end_date, #ddl_begin_date, #ddl_end_date').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    onShow () {
    },
    buildFilters () {
      // Vendedor
      this.filter.vendedor = (this.filters.filEmployees) ? this.filters.filEmployees.map(x => (x.id)) : []

      // Solicitante
      this.filter.solicitante = (this.filters.filUsers) ? this.filters.filUsers.map(x => (x.id)) : []

      // Solicitante
      this.filter.cliente = (this.filters.filCliente) ? this.filters.filCliente.map(x => (x.id)) : []

      // Processo
      if (this.filters.filProcesso) {
        this.filter.internal_code = this.filters.filProcesso
      } else {
        this.filter.internal_code = null
      }

      // ETD
      this.filter.etd_begin_date = this.filters.filEtdInicio
      this.filter.etd_end_date = this.filters.filEtdFim

      // DDL BBK
      this.filter.ddl_begin_date = this.filters.filDdlInicio
      this.filter.ddl_end_date = this.filters.filDdlFim

      // Operação
      this.filter.operation = []
      if (this.filters.filTipoOperacaoModalExportacao) {
        this.filter.operation.push(this.filters.filTipoOperacaoModalExportacao)
      }
      if (this.filters.filTipoOperacaoModalImportacao) {
        this.filter.operation.push(this.filters.filTipoOperacaoModalImportacao)
      }

      this.filter.id_status = null
    },
    applyStatusFilter (idStatus) {
      event.preventDefault()
      this.filter.id_status = idStatus
      this.$refs.vuetable.refresh()
    },
    // Loader
    applyFilter () {
      // Operation Filter
      this.filters.filTipoOperacaoModalExportacao = this.$refs.operation_expo.checked ? parseInt(this.$refs.operation_expo.value) : null
      this.filters.filTipoOperacaoModalImportacao = this.$refs.operation_impo.checked ? parseInt(this.$refs.operation_impo.value) : null

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuickSidebar()
      this.$refs.vuetable.refresh()
    },
    resetFilter (event) {
      event.preventDefault()
      this.hideQuickSidebar()

      // Vendedor
      this.filters.filEmployees = []

      // Solicitante
      this.filters.filUsers = []

      // Solicitante
      this.filters.filCliente = []

      this.filters.filProcesso = null

      this.filters.filEtdInicio = null
      this.filters.filEtdFim = null
      this.filters.filDdlInicio = null
      this.filters.filDdlFim = null

      // Operação
      this.filters.operation = []
      this.filters.filTipoOperacaoModalExportacao = 1
      this.filters.filTipoOperacaoModalImportacao = 2

      this.filter.id_status = null

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()
      this.$refs.vuetable.refresh()
    },
    hideQuickSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    userFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        EmployeeService.getUsuarios(filters).then(res => {
          _this.userList = res.data.data
          resolve(_this.userList)
        }).catch(err => {
          reject(err)
        })
      })
    },
    formatStatus (value) {
      if (value === null || value === undefined || !this.statusOptions.length) {
        return ' - '
      }

      let status = this.statusColor(value)

      return '<span style="color: ' + status.color + '">' + status.label + '</span>'
    },
    statusColor (value) {
      let colors = [
        '#f1ba26', /* Processando: amarelo */
        '#ff0000', /* Erro:  vermelho */
        '#5ec65f' /* Gerado: verde */
      ]

      let statusList = this.statusOptions.map((value, key) => {
        return {
          'id': key,
          'label': value,
          'color': colors[key]
        }
      })

      return statusList.find(obj => {
        if (obj.id === value) {
          return obj
        }
      })
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()

      if (this.$route.query.process) {
        if (this.$refs.vuetable.$data.tableData) {
          if (this.$refs.vuetable.$data.tableData.length > 0) {
            this.onRowClicked({ data: this.$refs.vuetable.$data.tableData[0] })
          }
        }
      }
    },
    onRowClicked (row) {
    },
    onActionsClicked (row) {
      this.showOptions(row.rowData)
    },
    refreshPage () {
      this.$refs.vuetable.refresh()
    }
  },
  computed: {
    vTableFields () {
      return this.fieldsTable
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      return this.$store.getters.aplliedFilters || null
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    },
    isSuperAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    }
  },
  watch: {
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style>
.swal2-popup {
  width: inherit !important;
}

.btn-a-color {
  color: #534B41 !important;
}

th:hover{
  color: #A69073 !important
}
</style>
